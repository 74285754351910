<template>
  <div class="cdp p-3 sm:p-6 transition-[padding]" :style="{
    '--cdp-hover-bg-color': hoverColor,
    '--cdp-hover-text-color': hoverTextColor,
    '--cdp-selected-bg-color': selectedColor,
    '--cdp-selected-text-color': selectedTextColor,
  }">
    <div class="cdp__container text-center">
      <div class="cdp__header">
        <div class="cdp__header--controls pb-4 flex justify-between">
          <slot name="control:subtract">
            <button data-cy="calendar_prev" type="button" :class="['control icon-prev', { 'invisible': !canDecrement} ]" @click="handleCalendarControl('subtract')"></button>
          </slot>

          <div class="month">{{ viewer_month }}</div>

          <slot name="control:add">
            <button data-cy="calendar_next" type="button" :class="['control icon-next', { 'invisible': !canIncrement }]" @click="handleCalendarControl('add')"></button>
          </slot>
        </div>

        <div class="cdp__header--days grid grid-cols-7 gap-2 xl:gap-4 text-[#cacaca] mb-2">
          <div v-for="(index, day) in viewer_days" :key="day">{{ day }}</div>
        </div>
      </div>

      <div class="cdp__calendar grid grid-cols-7 gap-1 xl:gap-4 items-center">
        <div
          v-for="(day, index) in viewer_dates"
          :key="day.formatted"
          class="cdp__calendar--day group"
          :class="day.options.classes"
        >
          <button type="button"
            :data-cy="`datepicker-day-${day.formatted}`"
            :data-cy-selected="selected_days.includes(day.formatted)"
            :data-cy-selectable="!day.options.inactive || day.options.deblockaged"
            :class="tw([
              // Basis
              'flex rounded-md bg-[#f0f0f0] text-qwr-dark-700 items-center justify-center flex-col w-full h-full cursor-pointer relative transition',

              // Multiple selection
              calendar.hovered_days.includes(day.formatted) && 'bg-(--cdp-hover-bg-color) text-(--cdp-hover-text-color)',

              // Selected state
              selected_days.includes(day.formatted) && 'bg-(--cdp-selected-bg-color) text-(--cdp-selected-text-color)',

              // Active state
              !day.options.inactive && !selected_days.includes(day.formatted) && 'hover:bg-(--cdp-hover-bg-color) hover:text-(--cdp-hover-text-color)',

              // Swimwear day
              'group-[.swimwear-day]:text-blue-500',
              selected_days.includes(day.formatted) && 'group-[.swimwear-day]:bg-blue-500 group-[.swimwear-day]:text-white',
              !day.options.inactive && !selected_days.includes(day.formatted) && 'hover:group-[.swimwear-day]:bg-blue-200',
              day.options.inactive && !selected_days.includes(day.formatted) && 'group-[.swimwear-day]:text-blue-500/30',

              // Special day
              'group-[.special-day]:text-amber-500',
              selected_days.includes(day.formatted) && 'group-[.special-day]:bg-amber-500 group-[.special-day]:text-white',
              !day.options.inactive && !selected_days.includes(day.formatted) && 'hover:group-[.special-day]:bg-amber-200',
              day.options.inactive && !selected_days.includes(day.formatted) && 'group-[.special-day]:text-amber-500/30',

              // Inactive state
              day.options.inactive && !day.options.deblockaged && !selected_days.includes(day.formatted) && 'bg-[#f0f0f0]/30 text-qwr-dark-700/30 cursor-default',
            ])"
            :tabindex="day.options.inactive ? -1 : 0"
            @click="handleDateSelection($event, day, index)"
            @mouseenter="calendar.in_selection ? handleDateHover($event, day) : null"
          >
            <slot name="day" v-bind="day">
              <div class="font-bold text-sm 2xl:text-base">
                {{ day.raw.getDate() }}
              </div>
            </slot>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import {computed, reactive} from 'vue';
  import { de, enUS, nl } from 'date-fns/locale';
  import { twMerge as tw } from 'tailwind-merge';

  import {
    addDays,
    addMonths,
    addYears,
    eachDayOfInterval,
    format, isAfter, isBefore, isSameDay,
    lastDayOfWeek,
    setDay,
    startOfMonth,
    startOfToday,
    startOfWeek,
    subMonths,
  } from 'date-fns';

  export interface ViewerDate {
    raw: Date
    formatted: string
    options: {
      inactive: boolean
      deblockaged?: boolean
      classes?: string
      price?: string
      date?: string
      status?: string
      lastDayInRange?: boolean
      notifications?: string[]
    }
  }

  export interface DateOptions {
    [index: string]: {
      inactive: boolean
      classes?: string
      price?: string
      date?: string
      status?: string
      notifications?: string[]
    }
  }

  export interface CalendarOptions {
    focus: Date,
    dates: {
      start: Date,
      end: Date | null,
      dateRange: string[]
    },
    in_selection: boolean,
    is_reselected: boolean,
    hovered_days: string[]
  }

  export interface Props {
    /**
     * v-model of the component.
     */
    startDate: Date,

    /**
     * End of the date, if `range` is not true, it'll be ignored.
     */
    endDate?: Date | null,

    /**
     * The selected dates in an array, this is never read, only written to.
     */
    dateRange?: string[],

    /**
     * An array of dates to override the calendar with. Through this way, dates can be set inactive or set as a special day.
     */
    dateOptions?: DateOptions,

    /**
     * The visible days in an array, this is never read, only written to.
     */
    dates?: ViewerDate[],

    /**
     * Allows user to set language of the datepicker.
     * Current supported languages: nl, de, en.
     */
    language?: 'nl' | 'en' | 'de' | 'fr'

    /**
     * Allows the user to toggle the calendar into single day mode or range mode
     */
    range?: boolean

    /**
     * Forces the user to always reselect the start date whenever we are in range mode.
     */
    forceReselect?: boolean

    /**
     * Allows user to define static amount of days selected during Range Selection.
     * Whenever number is defined as zero (0), this feature will be disabled.
     */
    staticRange?: number

    /**
     * Allows user to disable the legend
     */
    showLegend?: boolean,

    /**
     * Allows user to define legend items to show underneath the calendar
     */
    legendItems?: string[]

    /**
     * Disables dates that are in the past
     */
    disablePastDays?: boolean

    /**
     * The color for the hover state.
     */
    hoverColor?: string

    /**
     * The color for the hover state.
     */
    hoverTextColor?: string

    /**
     * The background color for the hover state.
     */
    selectedColor?: string

    /**
     * The text color for the hover state.
     */
    selectedTextColor?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    startDate: () => new Date(),
    endDate: () => new Date(),
    dateRange: () => [],
    dateOptions: () => ({}),
    dates: () => [],
    range: false,
    forceReselect: true,
    language: 'en',
    staticRange: 0,
    showLegend: true,
    legendItems: () => [],
    disablePastDays: true,
    hoverColor: 'transparent',
    hoverTextColor: '#000000',
    selectedColor: 'transparent',
    selectedTextColor: '#000000'
  });

  const emit = defineEmits([
    // prop emits
    'update:startDate',
    'update:endDate',
    'update:dateRange',
    'update:dates',

    // custom emits
    'day:change',
    'month:change',
  ]);

  // Supported languages
  const languages = {
    nl: nl,
    de: de,
    en: enUS
  };

  // Current date
  const today: Date = startOfToday();

  // Keep track of the dates and statusses of the calendar.
  const calendar = reactive<CalendarOptions>({
    // The main focus variable to calculate the month we are in.
    // Selecting the beginning of the month, to prevent collisions on days after the 28th.
    focus: new Date(props.startDate.getFullYear(), props.startDate.getMonth(), 1),

    // The variables to track the calendar selected dates.
    // These are required in order to work with optional props. (end date is not always required..)
    dates: {
      start: props.startDate,
      end: props.endDate,
      dateRange: props.dateRange
    },

    // The variable where we check if we are in a current date selection.
    // This is only possible to be true, whenever `props.range` is set to `true`.
    in_selection: false,

    // The variable where we check if the user is forced into startdate reselection.
    // This is only possible to be true, whenever `props.range=true` and `force_reselect=true`
    is_reselected: false,

    // Hovered days, this is handled by the `handleDateHover()` function. It includes possibly selected days in the calendar whenever in a date range selection.
    // This is only possible to be filled, whenever `props.range` is set to `true`.
    hovered_days: []
  });

  const startDate = computed({
    get: () => calendar.dates.start,
    set: (val) => {
      emit('update:startDate', val)
      return calendar.dates.start = val;
    }
  });

  const endDate = computed({
    get: () => calendar.dates.end,
    set: (val) => {
      emit('update:endDate', val)
      return calendar.dates.end = val;
    }
  });

  computed({
    get: () => selected_days,
    set: (val) => emit('update:dateRange', val)
  });

  computed({
    get: () => viewer_dates,
    set: (val) => emit('update:dates', val)
  });

  const viewer_dates = computed<ViewerDate[]>(() => {
    let month = calendar.focus.getMonth(),
      year = calendar.focus.getFullYear(),
      startDay = startOfWeek(new Date(year, month, 1), {weekStartsOn: 1}),
      endDay = lastDayOfWeek(new Date(year, month + 1, 0), {weekStartsOn: 1});

    const dates: ViewerDate[] = formatDates(eachDayOfInterval({start: startDay, end: endDay}));
    let had_selection_date = false;

    return dates.map((date, index) => {
      let shouldDeblock = false;

      if(date.formatted in props.dateOptions) {
        // #3322: Deblock the next inactive date from the starting date
        if(!had_selection_date && props.range && calendar.in_selection && isAfter(date.raw, props.startDate) && props.dateOptions[date.formatted].inactive) {
          had_selection_date = true;
          shouldDeblock = true;
        }

        date.options = {
          lastDayInRange: (selected_days.value.length > 1 ? selected_days.value[selected_days.value.length - 1] === date.formatted : false),
          ...date.options,
          ...props.dateOptions[date.formatted],
          // #3322: Deblock the next inactive date from the starting date
          ...(shouldDeblock ? { deblockaged: true } : {}),
          // #3322: Set inactive en block if we have matched an inactive date when in hotel range selection
          ...(had_selection_date && !shouldDeblock && !props.dateOptions[date.formatted].inactive ? {inactive: true, deblockaged: false} : {}),
        }
      }

      return date;
    });
  })

  const viewer_days = computed<{ [index: string]: number }>(() => {
    let headers: { [index: string]: number } = {};

    Array.from([1, 2, 3, 4, 5, 6, 0]).forEach(number => {
      // Format `EEEEEE` is representing ma/di/wo etc.
      headers[
        format(setDay(new Date(), number), "EEEEEE", {
          locale: languages[props.language]
        })
        ] = number;
    });

    return headers;
  })

  const viewer_month = computed<string>(() => {
    return format(calendar.focus, "MMMM yyyy", {
      locale: languages[props.language]
    });
  })

  const selected_days = computed<string[]>(() => {
    // Whenever we are in range modus
    if (props.range) {
      // Be sure to have an end date, and be sure they're not the same day to prevent invalid range errors of Date-FNS.
      if(endDate.value && !isSameDay(startDate.value, endDate.value) && startDate.value < endDate.value) {
        const list = formatDatesAsString(eachDayOfInterval({start: startDate.value, end: endDate.value}))

        // Update the start date
        emit('update:dateRange', list)

        return list;
      }
    }

    const list = formatDatesAsString([startDate.value]);

    // Update the start date
    emit('update:dateRange', list)

    return list;
  })

  /**
   * @param dates
   * @param returnFormat
   */
  function formatDates(dates: Date[]): ViewerDate[]
  {
    return dates.map((date): ViewerDate => {
      return {
        raw: date,
        formatted: format(date, "yyyy-MM-dd"),
        options: {
          inactive: props.disablePastDays && date < today
        }
      };
    });
  }

  /**
   * @param dates
   * @param dateFormat
   */
  function formatDatesAsString(dates: Date[], dateFormat: string = "yyyy-MM-dd"): string[]
  {
    return dates.map((date): string => {
        return format(date, dateFormat);
    });
  }

  function emitDayChange()
  {
    emit('day:change', format(startDate.value, 'yyyy-MM-dd'), endDate.value ? format(endDate.value, 'yyyy-MM-dd') : null);
  }

  /**
   * @param event
   * @param day
   * @param index
   */
  function handleDateSelection(event: Event, day: ViewerDate, index: number)
  {
    // Be sure to negate inactive days.
    if (day.options.inactive && !day.options.deblockaged) {
      return;
    }

    // Whenever we are not in the multi date option, be sure to set both dates.
    if (!props.range) {
      startDate.value = day.raw; // Update the start date
      endDate.value = day.raw;   // Update the end date
      emitDayChange();
      return;
    }

    // Whenever we are in range:
    // - Check if we should reselect the start date (qwr/platform#2703)
    // - Check if we have an end date set.
    // - Check if the date is before the current date we have selected.
    if ((props.forceReselect && !calendar.is_reselected) || endDate.value || startDate.value > day.raw) {
      // Set the calendar als reselected, so we can proceed with the normal date picking.
      calendar.is_reselected = true;

      // Set the start date
      startDate.value = day.raw

      // It's possible we have a static range, if so, be sure to update it correctly already :)
      if(props.staticRange && props.staticRange > 0) {
        endDate.value = addDays(day.raw, props.staticRange);
        emitDayChange();
        return;
      }

      // Otherwise: Be sure to activate the selection mode and always force the end date to be reset.
      calendar.in_selection = true;
      endDate.value = null;
      emitDayChange();
      return;
    }

    // If we come here, we only have a startdate set, without a static range.
    // So disable the selection mode, and set the new end date.
    calendar.in_selection = false;

    endDate.value = day.raw;
    emitDayChange();
  }

  /**
   * @param event
   */
  function handleCalendarControl(event: "add" | "subtract")
  {
    calendar.focus = (event === 'add' ? addMonths(calendar.focus, 1) : subMonths(calendar.focus, 1))

    emit('month:change', {event: event, focus: calendar.focus, dates: viewer_dates})
  }

  const canDecrement = computed(() => isAfter(calendar.focus, startOfMonth(today)));
  const canIncrement = computed(() => isBefore(calendar.focus, addYears(today, 5)));

  /**
   * @param event
   * @param day
   */
  function handleDateHover(event: Event, day?: ViewerDate)
  {

    if(!day || (day.options.inactive && !day.options.deblockaged) || day.raw < startDate.value) {
      return (calendar.hovered_days = []);
    }

    if(calendar.in_selection) {
      calendar.hovered_days = formatDatesAsString(eachDayOfInterval({start: startDate.value, end: day.raw}))
    }
  }
</script>
